<template lang="html">

  <section>
    <div class="buttons">
      <b-button
        type="is-success"
        @click="isCardModalActive = true"><i class="fas fa-plus"></i></b-button>
    </div>

    <b-modal v-model="isCardModalActive" :width="400" scroll="keep">
      <div class="modal-card" style="width: auto">
        <header class="modal-card-head">
          <p class="modal-card-title">Manage Categories</p>
        </header>
        <section class="modal-card-body">
          <form v-on:submit.prevent="submitCategory()" method="post">
            <div class="level">
              <div class="level-item" v-if="isAddingCategory === false" >
                <b-button class="is-fullwidth" type="is-success" name="button" @click="isAddingCategory = true">Add Category</b-button>
              </div>
              <div class="level-item" v-if="isAddingCategory === true">
                <b-button class="is-fullwidth" type="is-success" name="button" @click="submitCategory(); isAddingCategory = false;" :key="componentKey">Submit</b-button>
              </div>
              <div class="level-item" v-if="isAddingCategory === true" @click="isAddingCategory = false">
                <b-button class="is-fullwidth" type="is-danger" name="button" @click="isAddingCategory = true">Cancel</b-button>
              </div>
            </div>

            <b-field v-if="isAddingCategory === true">
              <b-input
                placeholder="New Category"
                v-model="new_cat.name"
                required>
              </b-input>
            </b-field>
          </form>

          <div class="existing-cats">
            <div class="level">
              <table class="table is-fullwidth">
                <tbody>
                  <tr
                    v-for="item in categories"
                    :value="item.id"
                    :key="item.id">
                    <td>{{item.name}}</td>
                    <td><b-button class="is-rounded is-danger is-small" @click="discardCategory(item.name)">Discard</b-button></td>
                  </tr>
                </tbody>
              </table>
            </div>
          </div>

        </section>
        <footer class="modal-card-foot">
          <b-button
            label="Close"
            @click="isCardModalActive = false" />
        </footer>
      </div>
    </b-modal>
  </section>

</template>

<script>
import axios from 'axios';
import { ToastProgrammatic as Toast } from 'buefy';
export default {
  data: () => {
    return {
      isCardModalActive: false,
      isAddingCategory: false,
      categories: [],
      new_cat: {
        name: null
      },
      componentKey: 0
    }
  },
  created: function () {
    this.getCategories()
  },
  methods: {
    getCategories() {
      axios({method: 'GET', url: '/api/v1/categories.json'})
      .then(response => {
        if (response.data.length != 0){
          this.categories = response.data;
        }
      })
    },
    submitCategory() {
      axios({
        method: 'POST',
        url: '/new_category',
        data: {new_cat: this.new_cat}
      }).then(response => {
        if(response.status === 200) {
          this.getCategories()
          Toast.open('Successfully created new category')
          this.new_cat.name = ''
        } else {
          Toast.open('There was an error submitting Your Demo Request')
        }
      })
    },
    discardCategory(selectedCategory) {
      const categoryObject = this.categories.filter(({name}) => name.includes(selectedCategory))
      let obj = categoryObject[0]
      axios({
        method: 'DELETE',
        url: '/delete_category',
        params: {discarded_category: obj}
      }).then(response => {
        if(response.status === 200) {
          this.$delete(this.categories, this.categories.indexOf(obj))
          Toast.open('Successfully deleted new category')
        } else {
          Toast.open('Could not delete category')
        }
      })
    },
  }
}
</script>
